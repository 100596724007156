<!-- <div class="" style="height: auto; text-align: left; padding: 25px">
  <div
    class="row"
    style="height: 50px; vertical-align: center; margin-top: 50px"
  >
    <div class="col-md-6" style="padding-top: 20px">
      Let's collaborate - Together, we can create Awesomeness !
    </div>
    <div class="col-md-6">&nbsp;</div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col-md-12">

    <span style="float:left;width: 50%;">
      <img src="./assets/pic/contact-page-pic.jpg" max-height="90%" width="350px">
    </span>

    <span style="float:right;width: 50%;padding-left: 100px;text-align: justify;padding-top: 5%;">
      <div style="border-color:rgb(144,238,144);border:2px;border-style:solid;padding: 5px;max-width:fit-content">Contact us</div>
    <div><a href="mailto:abidesign.in@gmail.com" target="_blank" class="fa fa-envelope" ></a> <span>&nbsp;&nbsp;</span><a href="mailto:abidesign.in@gmail.com">abidesign.in@gmail.com</a></div>
  </span>

</div>
    <div class="col-md-4 float-left mr-auto">
      <img
        class="img-fluid"
        src="./assets/pic/contact-page-pic.jpg"
        max-height="90%"
        width="350px"
      />
    </div>
    <div class="col-md-6 float-left">
      <div
        style="
          border-bottom: 2px;
          border-bottom-style: solid;
          max-width: fit-content;
        "
      >
        Contact us
      </div>
      <div>
        <a
          href="mailto:abidesign2019@gmail.com"
          target="_blank"
          class="fa fa-envelope"
        ></a>
        <span>&nbsp;&nbsp;</span
        ><a href="mailto:abidesign2019@gmail.com">abidesign2019@gmail.com</a>
      </div>
    </div>
    <div class="col-md-2 clearfix"></div>
  </div>
</div> -->

<div class="container mt-5">
  <div class="row">
    <div class="col-12">
      <div class="d-flex">
        <div class="d-flex">
          <h3 style="font-family: 'Lato', sans-serif" class="mt-1">
            CONTACT US
          </h3>
          <p class="dash ms-2"></p>
        </div>
        <div class="ms-3">
          <h4 style="font-family: 'Roboto', sans-serif">Lets Collaborate.</h4>
          <h4 style="font-family: 'Roboto', sans-serif">
            Don't Hesitate to Get in Touch
          </h4>
        </div>
      </div>
    </div>
    <div class="col-3 d-flex"></div>
  </div>
  <div class="row m-2 my-5">
    <div class="col-12 col-md-5">
      <img
        src="../../assets/images/contact page pic.jpg"
        class="w-100"
        alt=""
        appDisableRightClick
        appDisbaleDrag
      />
    </div>
    <div class="col-md-2"></div>
    <div class="col-12 col-md-5 mt-5">
      <div class="row">
        <div
          *ngIf="successMessage != ''"
          class="alert alert-success"
          role="alert"
        >
          {{ successMessage }}
        </div>
        <div *ngIf="errMessage != ''" class="alert alert-danger" role="alert">
          {{ errMessage }}
        </div>
      </div>
      <form class="row g-3" (ngSubmit)="onSubmit()" #f="ngForm">
        <div class="col-12">
          <span class="border border-3 fs-4 px-2 fw-bold">Contact Me</span>
        </div>
        <div class="col-12">
          <i class="fa fa-envelope-open" aria-hidden="true"></i>
          abidesign2019@gmail.com
        </div>
        <div class="col-12">
          <span class="border border-3 fs-6 px-2">Send a Message</span>
        </div>
        <div class="col-md-6">
          <label for="validationDefault01" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            ngModel
            required
            #name="ngModel"
            [ngClass]="{
              invalid: name.invalid && name.touched,
              valid: name.valid && name.touched
            }"
          />
        </div>
        <div class="col-md-6">
          <label for="validationDefault02" class="form-label"
            >Email address</label
          >
          <input
            type="email"
            class="form-control"
            name="email"
            ngModel
            email
            required
            #email="ngModel"
            [ngClass]="{
              invalid: email.invalid && email.touched,
              valid: email.valid && email.touched
            }"
          />
          <div id="emailHelp" class="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div class="col-12">
          <label for="validationDefault03" class="form-label"
            >Your Message</label
          >
          <textarea
            class="form-control"
            rows="4"
            name="message"
            ngModel
            required
            #message="ngModel"
            [ngClass]="{
              invalid: message.invalid && message.touched,
              valid: message.valid && message.touched
            }"
          ></textarea>
        </div>
        <div class="col-12 d-grid">
          <button class="btn btn-dark" type="submit" [disabled]="f.invalid">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              [hidden]="!loading"
            ></span>
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
