import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bird',
  templateUrl: './bird.component.html',
  styleUrls: ['./bird.component.css'],
})
export class BirdComponent implements OnInit {
  birds: { id: number; image: string; overlay: Boolean }[] = [
    { id: 1, image: '../../assets/images/bird/bird1.jpg', overlay: false },
    { id: 2, image: '../../assets/images/bird/bird2.jpg', overlay: false },
    { id: 3, image: '../../assets/images/bird/bird3.jpg', overlay: false },
  ];
  constructor() {}

  ngOnInit(): void {}
}
