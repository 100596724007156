import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisbaleDrag]',
})
export class DisbaleDragDirective {
  @HostListener('dragstart', ['$event'])
  onDragStart(event) {
    event.preventDefault();
  }
}
