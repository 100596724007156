<div class="container mt-5">
  <div class="row align-items-center">
    <div class="col">
      <h2 style="font-family: 'Lato', sans-serif">ABOUT US</h2>
    </div>
    <div class="d-none col-md-4"></div>
    <div class="col-5 col-md-3">
      <img
        src="../../assets/images/about1.jpg"
        style="width: 100%"
        alt="flower design"
        appDisableRightClick
        appDisbaleDrag
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-2">
      <p>
        We are two friends , Abi design it's our dream . we want to go far with
        this dream and we want to get your love .
      </p>
      <p>
        Abi design is a creative design house specialized in hand crafted design
        like handwoven design , hand embroidery work and hand painted design for
        printing which reflect on hight fashion garments and home textile . we
        create innovative design , which reflect upcoming trend and we create
        design for buyer's specific requirements.
      </p>
      <p>
        If you interested in our work , please connect with us at
        abidesign2019@gmail.com
      </p>
    </div>
    <div class="row">
      <div class="col-5 col-md-3 my-2">
        <img
          src="../../assets/images/about2.jpg"
          style="width: 100%"
          alt=""
          appDisableRightClick
          appDisbaleDrag
        />
      </div>
    </div>
  </div>
</div>
