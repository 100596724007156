<div id="bird-background">
  <div class="container" style="min-height: 78vh">
    <div class="row pt-5" style="min-height: 73vh">
      <div
        class="col-6 offset-2 offset-lg-0 col-lg-3 order-lg-3"
        appDisableRightClick
        appDisbaleDrag
      >
        <app-image-card [imageObject]="birds[2]"></app-image-card>
        <h6 class="text-end">TOUCAN SCARF</h6>
      </div>

      <div
        class="col-7 order-lg-2 offset-lg-0 col-lg-3 ms-lg-auto mt-lg-auto"
        appDisableRightClick
        appDisbaleDrag
      >
        <app-image-card [imageObject]="birds[1]"></app-image-card>
        <h6 class="text-end">SKETCHY SCARF</h6>
      </div>

      <div
        class="col-8 offset-3 order-lg-1 offset-lg-0 col-lg-3 mt-lg-5"
        appDisableRightClick
        appDisbaleDrag
      >
        <app-image-card [imageObject]="birds[0]"></app-image-card>
        <h6>KOLKATA LOVE SCARF</h6>
      </div>
    </div>
  </div>
</div>

<!-- <div id="bird-background"></div> -->
